import { useEffect, useState } from 'react';

import { useBrowserStorage } from 'hooks/UseBrowserStorage';

import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';

enum LAST_SEARCH {
  KEY = 'LastSearchData',
}

function useLastSearch() {
  const { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } =
    useBrowserStorage();
  const [lastSearchData, setLastSearchData] = useState<TLastSearch>();

  function setLastSearch(terms: string, searchLink: string) {
    setLocalStorageItem(LAST_SEARCH.KEY, {
      terms,
      expiryDate: (Date.now() + 12096e5).toString(),
      searchLink,
    });
  }

  function getLastSearch() {
    const searchTerms: TLastSearch = getLocalStorageItem(LAST_SEARCH.KEY);

    return searchTerms;
  }

  function removeLastSearch() {
    removeLocalStorageItem(LAST_SEARCH.KEY);
  }

  useEffect(() => {
    const lastSearchData = getLastSearch();
    if (lastSearchData) {
      if (parseInt(lastSearchData.expiryDate) < Date.now()) {
        removeLastSearch();
      } else setLastSearchData(lastSearchData);
    }
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getLastSearch,
    setLastSearch,
    removeLastSearch,
    lastSearchData,
  };
}

export { useLastSearch };
